<template>
    <Head>
        <Meta name="robots" content="noindex, nofollow" />
        <Link rel="icon" type="image/x-icon" href="https://frontend.coassemble.com/favicon.ico" />
        <Link rel="icon" type="image/png" sizes="32x32" href="https://frontend.coassemble.com/favicon-32x32.png" />
        <Link rel="icon" type="image/png" sizes="16x16" href="https://frontend.coassemble.com/favicon-16x16.png" />
    </Head>
    <slot />
</template>

<script>
import useTheme from '@/store/theme';

import { clearScriptTag } from '@/helpers/utilities';

export default {
    name: 'SecuredLayout',
    inject: ['$global'],
    mounted() {
        useTheme.setContrastBreakpoint(0.179);
        useTheme.generate({ colours: {} }, 0.612);

        this.clearCookieConsent();
        this.$http.get('/admin/user')
            .then(({ data }) => {
                if (!data) this.$router.push('/signin');
                this.$global.get();
            })
            .catch(() => this.$router.push('/signin'));
    },
    methods: {
        clearCookieConsent() {
            clearScriptTag('https://cmp.osano.com/169kHMTqRiT6d6UJj/7cff1750-71f9-459a-98b6-8124a6e83d48/osano.js');
            const el = document.querySelector('.osano-cm-window');
            if (el) el.remove();
        }
    }
};
</script>

<style lang="less">
.osano-cm-window { display: none !important; }
</style>
